
.uploadbtn{
    margin: 20px 0px 0px 70px;
    font-size: 22px;
    border: 0;
    border-radius: 5px;
    background: #1976d2;
    color: white;
    padding: 14px;
}
.mainDiv{
    display: flex;
    justify-content: center;
    flex-direction: column;
    .choosefile{
        /*   color: #1976d2; */
          padding: 63px  136px 0px 70px;
          font-size: 25px;
          display: flex;
      }

    .mainTop{
        display: flex;
    justify-content: flex-start;
    align-items: baseline;
}

.downloadSample{
    border: 0;
    border-radius: 5px;
    background: #11c56a;
    color: white;
    font-size: 25px;
    padding: 3px 8px 3px 8px;
}
    
}

