.update-order-container,
.update-referral-container {
  margin: 10px;
}

.text-success,
.text-secondary,
.text-danger {
  margin-bottom: 0;
}

.search-input-box {
    width: 40%;
    min-width: 200px;
    border-radius: 10px;
    border: 2px solid #000;
    padding: 10px;
    font-size: 14px;
    margin: 0 10px;
}

button {
  text-transform: capitalize !important;
}
